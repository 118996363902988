import logoUAQ from "../assets/images/logo_UAQ.svg";
import logoSAC from "../assets/images/SIMS_2.png";

export default function NavBar() {
  return (
    <nav className="relative flex items-center justify-between px-2 py-1 bg-dark-grey mb-3 shadow-lg w-full">
      <div className="container px-4 mx-auto flex items-center justify-between">
        <div className="flex items-center justify-start w-full">
          <div
              className="w-36 sm:w-12 md:w-16 lg:w-24 h-16 sm:h-12 md:h-16 lg:h-24 flex items-center justify-center">
            <img
                src={logoSAC}
                alt="Logo UAQ"
                className="h-auto w-auto max-h-full"
            />
          </div>
          <a className="sm:text-base lg:text-xl md:text-lg font-bold leading-relaxed inline-block sm:ml-1 ml-4 md:ml-8 mr-4 py-1 whitespace-nowrap uppercase text-white backdrop-blur-md">
            Semana Académica Cultural
          </a>
        </div>
      </div>
    </nav>
  );
}
