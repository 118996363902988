import React from "react";
import ExpInput from "./input";

const CardExp = (props) => {
  return (
    <div className="flex justify-center items-center min-h-screen">
      <div className="p-4 w-full mx-4 md:mx-auto md:max-w-lg rounded overflow-hidden bg-emerald-green">
        <div className="px-4 py-6">
          <div className="font-bold text-3xl">
            <p className="text-center text-white">
              CONSULTA TU ASISTENCIA A LOS EVENTOS
            </p>
          </div>
          <div className="my-2">
            <ExpInput />
          </div>
        </div>
        <div className="flex justify-between items-center px-4 mt-5">
          <div>
            <span className="inline-block bg-gray-200 rounded-full px-3 py-1 text-sm font-semibold text-gray-700 m-2">
              #SAC
            </span>
            <span className="inline-block bg-gray-200 rounded-full px-3 py-1 text-sm font-semibold text-gray-700 m-2">
              #FIF
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CardExp;
