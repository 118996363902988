import React, { useState, useEffect } from "react";
import "./App.css";
import NavBar from "./componets/header";
import CardExp from "./componets/card";
import Table from "./componets/table";
import Background3D from "./componets/Background3D";

//const url = 'https://sac-20230427.uc.r.appspot.com/workshops';
const url = process.env.REACT_APP_API_URL;

const RandomData = () => {
  const data = [];
  data.push(
    {
      expediente: 315267,
      nombre_taller: `Taller 1`,
      hora_taller: `${Math.floor(Math.random() * 24)}:00`,
    },
    {
      expediente: 315267,
      nombre_taller: `Taller 2`,
      hora_taller: `${Math.floor(Math.random() * 24)}:00`,
    }
  );
  return data;
};

function App() {
  const [showTable, setShowTable] = useState(false);
  const [expediente, setExpediente] = useState();

  useEffect(() => {
    if (expediente) {
      const fetchData = async () => {
        try {
          console.log("Expediente changed");
          const response = "Waos";
          const data = await response.json();
        } catch (err) {
          console.log(err);
        }
      };
      fetchData();
    }
  }, [expediente]);

  return (
    <>
      <NavBar />
      <Background3D />
      <CardExp setExpediente={setExpediente} />
    </>
  );
}

export default App;
