import React, { useRef, useEffect } from 'react';
import * as THREE from 'three';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';
import { isMobile } from 'react-device-detect';

const Background3D = () => {
  const scene = useRef(null);
  const camera = useRef(null);
  const renderer = useRef(null);
  const animatedModels = useRef([]);

  useEffect(() => {
    let frameWidth = window.innerWidth; // Ancho de la pantalla
    let frameHeight = window.innerHeight; // Altura de la pantalla

    scene.current = new THREE.Scene();
    camera.current = new THREE.PerspectiveCamera(75, frameWidth / frameHeight, 0.1, 1200);

    camera.current.position.set(0, 0, 450);

    renderer.current = new THREE.WebGLRenderer({ antialias: true, alpha: true });
    renderer.current.setSize(frameWidth, frameHeight);

    document.body.appendChild(renderer.current.domElement);

    renderer.current.domElement.style.position = 'fixed'; // Posición fija
    renderer.current.domElement.style.left = '0'; // Alinear a la esquina superior izquierda
    renderer.current.domElement.style.top = '0';
    renderer.current.domElement.style.zIndex = '-1';

    const loader = new GLTFLoader();

    const positions = [
      { x: -450, y: 0, scale: 0.4 },
      ...(isMobile ? [{ x: 0, y: 155, scale: 0.3 }] : []), // Oculta el elemento si no es móvil
      { x: 450, y: 0, scale: 0.4 }
    ];

    const loadModels = () => {
      positions.forEach((position) => {
        loader.load('models/the_sims_plumbob.glb', (gltf) => {
          const model = gltf.scene.clone();
          model.scale.set(position.scale, position.scale, position.scale);
          model.rotation.y = Math.PI / 2;
          model.position.x = position.x;
          model.position.y = position.y;

          animatedModels.current.push(model);

          scene.current.add(model);
        });
      });
    };

    loadModels();

    const handleResize = () => {
      frameWidth = window.innerWidth;
      frameHeight = window.innerHeight;

      camera.current.aspect = frameWidth / frameHeight;
      camera.current.updateProjectionMatrix();

      renderer.current.setSize(frameWidth, frameHeight);
    };

    window.addEventListener('resize', handleResize);

    const animate = () => {
      requestAnimationFrame(animate);

      // Rotar modelos animados
      animatedModels.current.forEach((model) => {
        model.rotation.y -= 0.01;
      });

      renderer.current.render(scene.current, camera.current);
    };

    animate();

    return () => {
      window.removeEventListener('resize', handleResize);
      document.body.removeChild(renderer.current.domElement);
    };
  }, []);

  return null;
};

export default Background3D;
